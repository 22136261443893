import { storeToRefs } from 'pinia'

import { useDraftStore } from '~/stores/draft'
import { useDraftPromoStore } from '~/stores/draftPromo'
import { useDraftTrackStore } from '~/stores/draftTrack'
import { useInfluencersStore } from '~/stores/influencers'
import { useMiscBandSignupReferralStore } from '~/stores/miscBandSignupReferral'
import { useTagStore } from '~/stores/tag'
import { useUserStore } from '~/stores/user'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $localePath, $pinia } = useNuxtApp()

  const {
    id: DRAFT_ID,
    influencers: DRAFT_INFLUENCERS,
    influencers_count: DRAFT_INFLUENCER_COUNT,
    track: TRACK_ID,
  } = storeToRefs(useDraftStore($pinia))
  const { code: BAND_SIGNUP_REFERRAL_CODE } = storeToRefs(
    useMiscBandSignupReferralStore($pinia),
  )

  const { UPDATE_GROOVIZ } = useUserStore($pinia)
  const { SET_CURRENT: SET_CURRENT_DRAFT, GET_CURRENT_DRAFT_INFLUENCERS } =
    useDraftStore($pinia)
  const { FETCH: FETCH_DRAFT_TRACK } = useDraftTrackStore($pinia)
  const { RESET: RESET_DRAFT_PROMO } = useDraftPromoStore($pinia)
  const { FETCH_SET: FETCH_INFLUENCER_SET } = useInfluencersStore($pinia)
  const { FETCH: FETCH_TAGS } = useTagStore($pinia)
  const {
    SYNC_CODE: SYNC_BAND_SIGNUP_REFERRAL_CODE,
    PUSH_CODE_TO_PROMO: PUSH_BAND_SIGNUP_REFERRAL_CODE_TO_PROMO,
  } = useMiscBandSignupReferralStore($pinia)

  const draftIdToGet = to.params.id || to.query.draftId || undefined
  const storeDraftDoesNotMatchUrlDraft: boolean =
    !!draftIdToGet && DRAFT_ID.value !== Number(draftIdToGet)
  const currentDraftDoesNeedsToLoadInfluencers: boolean =
    DRAFT_INFLUENCER_COUNT.value !== DRAFT_INFLUENCERS.value.length

  if (storeDraftDoesNotMatchUrlDraft) {
    try {
      RESET_DRAFT_PROMO({ noServerUpdate: true })
      await SET_CURRENT_DRAFT(Number(draftIdToGet))
      // get the first batch of draft infs, but not all of them so that pages can load
      await GET_CURRENT_DRAFT_INFLUENCERS()
      await FETCH_INFLUENCER_SET(DRAFT_INFLUENCERS.value)
      // continue to get the rest of the influencers if there are any without blocking the page
      GET_CURRENT_DRAFT_INFLUENCERS({ fetchAll: true })
    } catch (err) {
      return navigateTo($localePath('/'))
    }
  } else if (currentDraftDoesNeedsToLoadInfluencers) {
    try {
      // get the first batch of draft infs, but not all of them so that pages can load
      await GET_CURRENT_DRAFT_INFLUENCERS()
      await FETCH_INFLUENCER_SET(DRAFT_INFLUENCERS.value)
      // continue to get the rest of the influencers if there are any without blocking the page
      GET_CURRENT_DRAFT_INFLUENCERS({ fetchAll: true })
    } catch (err) {
      return navigateTo($localePath('/'))
    }
  }

  await UPDATE_GROOVIZ().catch()

  await Promise.all([
    callOnce('FETCH_TAGS', FETCH_TAGS),
    SYNC_BAND_SIGNUP_REFERRAL_CODE(),
    FETCH_DRAFT_TRACK(TRACK_ID.value),
  ]).catch(() => true)

  // Try to apply referral code after fetching progressive promos
  if (BAND_SIGNUP_REFERRAL_CODE.value.length)
    await PUSH_BAND_SIGNUP_REFERRAL_CODE_TO_PROMO().catch(() => true)
})
